<template>
  <section>
    <div class="uk-container uk-container-large">
      <div class="uk-flex-center" data-uk-grid>
        <div class="uk-text-center uk-margin-medium-bottom">
          <h2 class="uk-heading-small uk-text-bold uk-margin-small-bottom uk-margin-top uk-margin-left uk-margin-right">
            Design your own
          </h2>
          <img
            class="uk-margin-left uk-margin-right uk-text-primary"
            style="height: 24px"
            src="@/web/img/brand/Star.svg"
            data-uk-svg
            alt="Varsity Base logo"
          />
          <p class="uk-text-lead uk-margin-auto-left uk-margin-auto-right" style="max-width: 600px">
            Varsity Base is proud to offer a large variety of materials, patches and embroidery to help build a perfect
            jacket. Designing your own unique jacket has never been easier.
          </p>
        </div>
      </div>
    </div>
    <div class="uk-container uk-container-large">
      <div class="uk-text-center">
        <img
          class="uk-position-relative"
          style="height: 72px; margin-bottom: -36px; z-index: 9"
          :data-src="vbArrowImg"
          data-uk-svg
          alt="Arrow icon"
        />
      </div>
      <div id="builderPreview" class="uk-overflow-hidden vb-rounded-lg uk-background-muted">
        <div class="uk-padding">
          <div class="uk-grid-large" data-uk-grid>
            <div class="uk-width-1-1 uk-width-1-2@m uk-width-2-3@xl uk-position-relative">
              <div class="uk-position-relative uk-text-center">
                <div class="uk-position-absolute uk-position-center">
                  <img
                    :data-src="getImgPath('/img/uploads/circles.svg')"
                    data-uk-img
                    data-uk-scrollspy="cls: uk-animation-kenburns; repeat: true"
                    alt="Circles"
                  />
                </div>

                <img
                  class="uk-padding uk-padding-remove-vertical uk-position-relative"
                  :data-src="imagePath"
                  data-uk-img
                  alt="Letterman Jacket"
                />
              </div>
            </div>
            <div class="uk-width-1-1 uk-width-1-2@m uk-width-1-3@xl uk-position-relative">
              <div class="uk-padding uk-background-default vb-rounded uk-box-shadow-medium uk-flex uk-flex-column">
                <div v-for="option in options" :key="option.id" class="uk-margin-bottom">
                  <p class="uk-margin-small uk-text-small">
                    <strong>{{ option.title }}</strong>
                  </p>
                  <div class="uk-width-auto@s uk-flex uk-flex-middle uk-flex-between">
                    <div>
                      <ul class="uk-child-width-auto uk-grid-small uk-margin-remove-bottom b-colors" data-uk-grid>
                        <li v-for="(val, n) in option.values" :key="n">
                          <label
                            class="w-radio-img uk-position-relative b-radio-img uk-flex uk-flex-middle uk-flex-center uk-flex-wrap"
                            :for="option.id + n"
                          >
                            <input
                              :id="option.id + n"
                              v-model="formData[option.id]"
                              class="uk-radio uk-margin-remove"
                              type="radio"
                              :value="val.stripless ? '' : val['title']"
                            />
                            <span
                              class="w-radio-img__thumb b-radio-color__thumb uk-flex uk-flex-middle uk-flex-center uk-border-rounded uk-margin-remove"
                              :data-uk-tooltip="`title: ${val['title']}`"
                              style="margin-bottom: 0 !important"
                            >
                              <span
                                v-if="val.stripless"
                                class="uk-display-block uk-position-relative"
                                style="background-color: #454545"
                              >
                                <span class="uk-position-center" style="opacity: 0.25" data-uk-icon="close" />
                              </span>
                              <span v-else class="uk-display-block" :style="`background-color: ${val['hex']}`" />
                            </span>
                          </label>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <a class="uk-link-muted uk-text-small" :href="customizationLink" @click="loading = true">
                        + 24 more options
                      </a>
                    </div>
                  </div>
                </div>
                <div class="uk-margin-auto-top">
                  <a
                    class="uk-button uk-button-secondary uk-flex-inline uk-text-bold vb-btn-hover-scale"
                    style="border-radius: 48px"
                    :href="customizationLink"
                    @click="loading = true"
                  >
                    <span class="uk-margin-remove">Proceed</span>
                  </a>
                </div>
                <div v-if="loading" class="uk-overlay-default vb-overlay-cart uk-position-cover" />
                <div v-if="loading" class="uk-position-center vb-spinner-cart-update" data-uk-spinner />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import vbArrowImg from '@/web/img/brand/vb-arrow.svg'
import { getImgPath } from '@/shared/js/helpers'

export default {
  name: 'BuilderPreview',
  data() {
    return {
      vbArrowImg,
      loading: false,
      options: [
        {
          id: 'body',
          title: 'Color of wool body:',
          values: [
            { title: 'Black', hex: '#000' },
            { title: 'Bright White', hex: '#e2e2e2' },
            { title: 'Scarlet', hex: '#9e0020' },
            { title: 'Bright Royal', hex: '#05265d' },
            { title: 'Kelly Green', hex: '#053b23' },
          ],
        },
        {
          id: 'sleeves',
          title: 'Color of leather sleeves:',
          values: [
            { title: 'Black', hex: '#000' },
            { title: 'Bright White', hex: '#e2e2e2' },
            { title: 'Old Gold', hex: '#985514' },
          ],
        },
        {
          id: 'knit',
          title: 'Color of knit trim stripes:',
          values: [
            { title: 'No stripes', stripless: true },
            { title: 'Black', hex: '#000' },
            { title: 'Bright White', hex: '#e2e2e2' },
            { title: 'Old Gold', hex: '#985514' },
          ],
        },
      ],
      formData: {
        body: 'Black',
        sleeves: 'Bright White',
        knit: '',
      },
    }
  },
  computed: {
    imagePath() {
      let imgSrc = '/img/half-cookie/'

      imgSrc += this.formData.body.replace(' ', '-')
      imgSrc += '_' + this.formData.sleeves.replace(' ', '-')

      if (this.formData.knit) {
        imgSrc += '_' + this.formData.knit.replace(' ', '-')
      }
      return getImgPath(`${imgSrc}.png`)
    },
    customizationLink() {
      return `/customize/wizard_default_jacket?body=${this.formData.body}&sleeves=${this.formData.sleeves}&knit=${this.formData.knit}`
    },
  },
  methods: {
    getImgPath,
  },
}
</script>
