<template>
  <div class="uk-grid-match uk-grid-medium uk-margin-bottom" data-uk-grid>
    <div v-if="banners[0]" class="uk-width-1-3@m uk-width-1-2@l">
      <a
        class="uk-position-relative uk-overflow-hidden vb-rounded-lg uk-height-1-1 uk-background-secondary uk-display-block vb-hero-thumb"
        :href="banners[0].link_url"
      >
        <video
          v-if="banners[0].video"
          :src="banners[0].video"
          style="width: 100%; height: 100%; object-fit: cover; aspect-ratio: 1 / 1"
          loop
          muted
          playsinline
          autoplay
        />
        <img
          v-else-if="banners[0].image"
          :data-src="banners[0].image"
          class="vb-hover-scale__inner uk-width-1-1 uk-height-1-1"
          data-uk-scrollspy="cls: uk-animation-kenburns; repeat: true"
          data-uk-img
          style="object-fit: cover; aspect-ratio: 1 / 1"
          width="1920"
          height="1080"
          alt="banner 1"
        />
        <span class="uk-overlay-primary-light uk-position-cover uk-display-block"></span>
        <span class="uk-position-bottom-left" style="margin: 32px">
          <strong class="uk-h2 uk-text-bold uk-display-block uk-margin-small-bottom vb-text-white">
            {{ banners[0].name }}
          </strong>
          <span class="uk-text-bold uk-text-small vb-text-white" style="border-bottom: 2px solid">
            {{ banners[0].link_text }}
          </span>
        </span>
        <span v-if="banners[0].description" class="uk-position-top-left" style="margin: 32px">
          <span
            class="uk-display-block uk-text-bold uk-margin-bottom uk-width-4-5 uk-width-2-3@s uk-text-lead"
            style="color: white"
          >
            {{ banners[0].description }}
          </span>
        </span>

        <img
          class="uk-position-top-right uk-visible@s"
          :data-src="logoShortImg"
          style="height: 24px; margin: 32px"
          data-uk-img
          alt="Varsity Base"
        />
      </a>
    </div>
    <div v-if="banners[1]" class="uk-width-1-3@m uk-width-1-4@l">
      <div class="uk-grid-medium" data-uk-grid>
        <div>
          <a
            class="uk-position-relative uk-overflow-hidden vb-rounded-lg uk-height-1-1 uk-background-secondary uk-display-block vb-hover-scale"
            :href="banners[1].link_url"
          >
            <video
              v-if="banners[1].video"
              :src="banners[1].video"
              style="width: 100%; height: 100%; object-fit: cover; aspect-ratio: 1 / 1"
              loop
              muted
              playsinline
              autoplay
            />
            <img
              v-else-if="banners[1].image"
              :data-src="banners[1].image"
              class="vb-hover-scale__inner uk-width-1-1 uk-height-1-1 vbMobileAnimatedKB"
              data-uk-img
              style="object-fit: cover; aspect-ratio: 1 / 1"
              width="1920"
              height="1080"
              alt="banner 2"
            />
            <span class="uk-overlay-primary-light uk-position-cover uk-display-block" />

            <span class="uk-position-bottom-left" style="margin: 32px">
              <strong class="uk-h3 uk-text-bold uk-display-block uk-margin-small-bottom vb-text-white">
                {{ banners[1].name }}
              </strong>
              <span class="vb-text-white uk-text-bold uk-text-small" style="border-bottom: 2px solid">
                {{ banners[1].link_text }}
              </span>
            </span>
            <img
              class="uk-position-top-right uk-width-1-6@m uk-width-1-4 vb-product-label"
              style="max-width: 60px; margin: 16px; transform: rotate(-30deg)"
              :data-src="logoNewInvImg"
              data-uk-img
              alt="New"
            />
          </a>
        </div>
        <div v-if="banners[2]">
          <a
            class="uk-position-relative uk-overflow-hidden vb-rounded-lg uk-height-1-1 uk-background-secondary uk-display-block vb-hover-scale"
            :href="banners[2].link_url"
          >
            <video
              v-if="banners[2].video"
              :src="banners[2].video"
              style="width: 100%; height: 100%; object-fit: cover; aspect-ratio: 1 / 1"
              loop
              muted
              playsinline
              autoplay
            />
            <img
              v-else-if="banners[2].image"
              :data-src="banners[2].image"
              class="vb-hover-scale__inner uk-width-1-1 uk-height-1-1 vbMobileAnimatedKB"
              data-uk-img
              style="object-fit: cover; aspect-ratio: 1 / 1"
              width="1920"
              height="1080"
              alt="banner 3"
            />
            <span class="uk-overlay-primary-light uk-position-cover uk-display-block" />
            <span class="uk-position-bottom-left" style="margin: 32px">
              <strong class="uk-h3 uk-text-bold uk-display-block uk-margin-small-bottom vb-text-white">
                {{ banners[2].name }}
              </strong>
              <span class="uk-text-bold uk-text-small vb-text-white" style="border-bottom: 2px solid">
                {{ banners[2].link_text }}
              </span>
            </span>
            <img
              class="uk-position-top-right uk-width-1-6@m uk-width-1-4 vb-product-label"
              style="max-width: 60px; margin: 16px; transform: rotate(-30deg)"
              :data-src="logoNewImg"
              data-uk-img
              alt="New"
            />
          </a>
        </div>
      </div>
    </div>
    <div v-if="banners[3]" class="uk-width-1-3@m uk-width-1-4@l">
      <a
        class="uk-position-relative uk-overflow-hidden vb-rounded-lg uk-height-1-1 uk-background-secondary uk-display-block vb-hover-scale"
        :href="banners[3].link_url"
      >
        <video
          v-if="banners[3].video"
          :src="banners[3].video"
          style="width: 100%; height: 100%; object-fit: cover; aspect-ratio: 1 / 1"
          loop
          muted
          playsinline
          autoplay
        />
        <img
          v-else-if="banners[3].image"
          :data-src="banners[3].image"
          class="vb-hover-scale__inner uk-width-1-1 uk-height-1-1 vbMobileAnimatedKB"
          data-uk-img
          style="object-fit: cover; aspect-ratio: 1 / 1"
          width="1920"
          height="1080"
          alt="banner 4"
        />
        <span class="uk-overlay-primary-light uk-position-cover uk-display-block" />
        <span class="uk-position-bottom-left" style="margin: 32px">
          <strong class="uk-h3 uk-text-bold uk-display-block uk-margin-small-bottom vb-text-white">
            {{ banners[3].name }}
          </strong>
          <span class="uk-text-bold uk-text-small vb-text-white" style="border-bottom: 2px solid">
            {{ banners[3].link_text }}
          </span>
        </span>
        <img
          class="uk-position-top-left"
          :data-src="logoShortDarkImg"
          style="height: 24px; margin: 32px"
          data-uk-img
          alt="Varsity Base"
        />
      </a>
    </div>
  </div>
</template>

<script>
import logoShortImg from '@/web/img/brand/logo-short.svg'
import logoShortDarkImg from '@/web/img/brand/logo-short--dark.svg'
import logoNewInvImg from '@/web/img/brand/label-new-inv.svg'
import logoNewImg from '@/web/img/brand/label-new.svg'

export default {
  name: 'HomeBanners',
  props: {
    banners: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      logoShortImg,
      logoShortDarkImg,
      logoNewInvImg,
      logoNewImg,
    }
  },
  mounted() {
    document.addEventListener('touchstart', this.videoAutoplay)

    const vbMobileAnimatedKB = document.querySelectorAll('.vbMobileAnimatedKB')

    if (vbMobileAnimatedKB) {
      vbMobileAnimatedKB.forEach(item => {
        if (window?.matchMedia('(max-width: 767px)')?.matches) {
          item.setAttribute('data-uk-scrollspy', 'cls: uk-animation-kenburns; repeat: true')
        }
      })
    }
  },
  unmounted() {
    document.removeEventListener('touchstart', this.videoAutoplay)
  },
  methods: {
    videoAutoplay() {
      const videos = document.querySelectorAll('video')
      videos?.forEach(video => {
        video.play()
      })
    },
  },
}
</script>
